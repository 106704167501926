window.klaroConfig = {
  translations: {
    de: {
      privacyPolicyUrl: '/de/datenschutz',
      ok: 'Alle akzeptieren',
      decline: 'Alle ablehnen',
      calendly: {
        title: 'Calendly',
        description: 'Wir nutzen Calendly für Terminvereinbarungen.',
      },
      microsoftBookings: {
        title: 'Microsoft Bookings',
        description: 'Wir nutzen Microsoft Bookings für Terminvereinbarungen.',
      },
      visitorAnalytics: {
        title: 'Visitor Analytics',
        description: 'Wir nutzen Visitor Analytics um Besucherstatistiken zu sammeln.',
      },
      googleTagManager: {
        title: 'Google Tag Manager',
        description: 'Wir nutzen den Google Tag Manager um Besucherstatistiken zu sammeln.',
      },
      linkedIn: {
        title: 'LinkedIn',
        description: 'Wir nutzen LinkedIn un Marketing-Kampagnen auszuwerten.',
      },
      youTube: {
        title: 'YouTube',
        description: 'Wir nutzen YouTube um Videos auf unserer Seite einzubinden.',
      },
      vimeo: {
        title: 'Vimeo',
        description: 'Wir nutzen Vimeo um Videos auf unserer Seite einzubinden.',
      },
      purposes: {
        analytics: 'Besucherstatistiken',
        embeds: 'Inhalte von Drittanbietern',
        functional: {
          description: null,
        },
      },
      consentNotice: {
        description: 'Wir nutzen Cookies zum Speichern Ihrer Anmeldedaten, für eine sichere Anmeldung, zur Erhebung statistischer Daten, zur Optimierung der Website-Funktionen sowie zum Onlinemarketing und Remarketing, um Ihnen eine angenehmere Erfahrung zu bieten. Klicken Sie auf „Alle akzeptieren“, um Cookies zu akzeptieren oder auf "Cookies verwalten“, um weitere Informationen zu erhalten, diese Einstellungen aufzurufen und Cookies jederzeit abzuwählen.',
        learnMore: 'Cookies verwalten',
      },
      contextualConsent: {
        description: 'Dieser Inhalt wird von {title} bereitgestellt. Wenn Sie diesen Inhalt laden, werden personenbezogenen Daten zu Ihrer Person verarbeitet und Cookies von Dritten gesetzt, die auch zur Bildung von Nutzerprofilen und zu Marketingzwecken verwendet werden. Wenn Sie den Inhalt laden, willigen Sie ein, dass Ihre personenbezogenen Daten trotz eines bestehenden Risikos für Sie in die USA übertragen werden. Das Datenschutzniveau nach der DSGVO kann hierbei nicht gewährleistet werden. Weitere Informationen hierzu finden Sie in unserer Datenschutzerklärung. Sie können diese Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen.'
      },
    },
    en: {
      privacyPolicyUrl: '/en/privacy-protection',
      ok: 'Accept all',
      decline: 'Decline all',
      calendly: {
        title: 'Calendly',
        description: 'We use Calendly to make appointments.',
      },
      microsoftBookings: {
        title: 'Microsoft Bookings',
        description: 'We use Microsoft Bookings to make appointments.',
      },
      visitorAnalytics: {
        title: 'Visitor Analytics',
        description: 'We use Visitor Analytics to collect visitor statistics.',
      },
      googleTagManager: {
        title: 'Google Tag Manager',
        description: 'We use Google Tag Manager to collect visitor statistics.',
      },
      linkedIn: {
        title: 'LinkedIn',
        description: 'We use LinkedIn to evaluate marketing campaigns.',
      },
      youTube: {
        title: 'YouTube',
        description: 'We use YouTube to embed videos on our site.',
      },
      vimeo: {
        title: 'Vimeo',
        description: 'We use Vimeo to embed videos on our site.',
      },
      purposes: {
        analytics: 'Visitor statistics',
        embeds: 'Third party content',
        functional: {
          description: null,
        },
      },
      consentNotice: {
        description: 'We use cookies to store your login information, provide a secure login, collect statistical data, optimize website features, and for online marketing and remarketing to provide you with a more enjoyable experience. Click "Accept All" to accept cookies or "Manage Cookies" for more information, to access these settings, and to opt out of cookies at any time.',
        learnMore: 'Manage cookies',
      },
      consentModal: {
        description: 'Here you can rate and customize the services we want to use on this website. You are in charge! Enable or disable the services as you see fit.',
      },
      service: {
        disableAll: {
          description: 'With this button you can enable or disable all services.',
        },
      },
    }
  },
  services: [
    {
      name: 'calendly',
      purposes: ['functional'],
    },
    {
      name: 'microsoftBookings',
      purposes: ['functional'],
      required: true,
    },
    {
      name: 'youTube',
      purposes: ['embeds'],
    },
    {
      name: 'vimeo',
      purposes: ['embeds'],
    },
    {
      name: 'visitorAnalytics',
      purposes: ['analytics'],
    },
    {
      name: 'googleTagManager',
      purposes: ['analytics'],
    },
    {
      name: 'linkedIn',
      purposes: ['analytics'],
    },
  ],
  acceptAll: true,
};
