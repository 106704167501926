(function ($) {
    "use strict";

    var ANIMATION_DURATION = 300;

    function Site() {
        this._$window = $(window);
        this._$document = $(document);

        this._init();
    }

    Site.prototype = {
        _init: function () {
            this
                ._initMainMenu()
                ._initHeader()
                ._initStage()
                ._initSlider()
                ._initTiles()
                ._initClickToEnhance()
                ._initShareLinks()
                ._initForms()
                ._initIframes()
                ._initAnimations()
                ._initShowElement()
                ._initCookieConsent();

            return this;
        },

        _initMainMenu: function () {
            var $menu = $('#menu-container'),
                $toggle = $('#menu-toggle'),
                $header = $('#header'),
                hasFixedHeader = null,
                state = false;

            function open() {
                $toggle.addClass('header__menu-toggle--open');
                $menu.addClass('header__menu-container--open');

                if (!$header.hasClass('header--fixed')) {
                    $header.addClass('header--fixed');
                    hasFixedHeader = false;
                }

                state = true;
            }

            function close() {
                $menu.removeClass('header__menu-container--open');
                $menu.find('.header__menu--show-sub-menu').removeClass('header__menu--show-sub-menu');
                $menu.find('.header__menu-item--open').removeClass('header__menu-item--open');
                $toggle.removeClass('header__menu-toggle--open');

                if (false === hasFixedHeader) {
                    $header.removeClass('header--fixed');
                    hasFixedHeader = null;
                }

                state = false;
            }

            $toggle.on('click', function () {
                if (state) {
                    close();
                } else {
                    open();
                }
            });

            $menu.on('click', '.header__menu-item--has-children > a', function (event) {
                var $link = $(event.currentTarget),
                    $menuItem = $link.closest('.header__menu-item'),
                    $menu = $link.closest('.header__menu');

                event.preventDefault();

                if (!$menuItem.hasClass('header__menu-item--open')) {
                    $menu.find('.header__menu-item--open').removeClass('header__menu-item--open');

                    $menuItem.addClass('header__menu-item--open');
                    $menu.addClass('header__menu--show-sub-menu');

                    if (!$header.hasClass('header--fixed')) {
                        $header.addClass('header--fixed');
                        hasFixedHeader = false;
                    }
                } else {
                    $menuItem.removeClass('header__menu-item--open');
                    $menu.removeClass('header__menu--show-sub-menu');

                    if (false === hasFixedHeader) {
                        $header.removeClass('header--fixed');
                        hasFixedHeader = null;
                    }
                }

                $link.blur();
            });

            $menu.on('click', '.header__menu-back-link', function (event) {
                event.preventDefault();

                $menu.find('.header__menu--show-sub-menu').removeClass('header__menu--show-sub-menu');
                window.setTimeout(function () {
                    $menu.find('.header__menu-item--open').removeClass('header__menu-item--open');
                }, ANIMATION_DURATION);
            });

            this._$window.on('resize', function () {
                if (state) {
                    close();
                }
            });

            this._$document.on('click', function (event) {
                if (state
                    && $toggle.get(0) !== event.target
                    && !$.contains($toggle.get(0), event.target)
                    && $menu.get(0) !== event.target
                    && !$.contains($menu.get(0), event.target)
                ) {
                    close();
                }
            });

            return this;
        },

        _initHeader: function () {
            var $header = $('#header'),
                $firstContentElement = $('#content').children().first(),
                state = false,
                scrollHandler = function () {
                    var newState = this._$window.scrollTop() > 0;

                    if (newState !== state) {
                        $header.toggleClass('header--fixed', newState);
                        state = newState;
                    }
                }.bind(this);

            if (!$firstContentElement.hasClass('below-header')) {
                $header.addClass('header--fixed');
            } else {
                this._$window.on('scroll', scrollHandler);
                scrollHandler();
            }

            $('#share-icons-toggle').on('click', function (event) {
                event.preventDefault();

                $('#share-icons-container').toggleClass('header__share-links-icons--open');
            });

            return this;
        },

        _initStage: function () {
            $('.ce-stage').on('click', '.ce-stage__scroll-down', function (event) {
                var $header = $('#header'),
                    offset = 0 < $header.length ? this._$window.height() - $header.height() : this._$window.height();

                this._smoothScroll(offset);

                event.preventDefault();
                event.currentTarget.blur();
            }.bind(this));

            return this;
        },

        _initSlider: function () {
          $('[data-widget="slider"]').each(function () {
            var $element = $(this);
            var $controls = $element.find('[data-target="controls"]').first();
            var $items = $element.find('[data-target="items"]').first();

            function onChange(info) {
              [...info.slideItems].forEach((element, index) => {
                if (index === info.index) {
                  element.classList.add('slider__item--current');
                } else {
                  element.classList.remove('slider__item--current');
                }
              });
            }

            var slider = tns({
              container: $items.get(0),
              controls: 0 !== $controls.length,
              controlsContainer: $controls.get(0) || false,
              nav: $element.data('nav') || false,
              gutter: 20,
              items: $element.data('items') || 1,
              autoplay: $element.data('autoplay') || false,
              autoplayButtonOutput: false,
              autoplayTimeout: $element.data('autoplayTimeout') || 5000,
              lazyload: false,
              autoHeight: $element.data('autoHeight') || false,
              autoWidth: $element.data('autoWidth') || false,
              center: 'boolean' === typeof $element.data('center') ? $element.data('center') : true,
              loop: $element.data('loop') || false,
              responsive: {
                500: {
                  gutter: 30,
                  items: $element.data('itemsSm') || 1,
                },
                769: {
                  items: $element.data('itemsMd') || 1,
                },
                1240: {
                  items: $element.data('itemsLg') || 1,
                },
                1800: {
                  items: $element.data('itemsXl') || 1,
                },
                2000: {
                  items: $element.data('itemsXxl') || 1,
                },
              },
              onInit: onChange
            });

            slider.events.on('indexChanged', onChange);

            $element.addClass('slider--active');
          });

          return this;
        },

        _initTiles: function () {
            $('.ce-tiles').each(function (index, container) {
                var $container = $(container),
                    $columns = $container.find('.ce-tiles__column'),
                    $tiles = $container.find('.ce-tile'),
                    currentMode = 'desktop';

                $columns.each(function (columnIndex, column) {
                    $(column).find('.ce-tile').each(function (tileIndex, tile) {
                        $(tile)
                            .data('originalColumn', columnIndex)
                            .data('originalSorting', tileIndex);
                    });
                });

                function handler() {
                    var newMode = 992 <= this._getViewportWidth() ? 'desktop' : 'mobile';

                    function sortHelper(a, b, sortBy) {
                        var sortingA = $(a).data(sortBy) || 0,
                            sortingB = $(b).data(sortBy) || 0;

                        if (sortingA === sortingB) {
                            return 0;
                        } else {
                            return sortingA < sortingB ? -1 : 1;
                        }
                    }

                    if (newMode !== currentMode) {
                        if ('mobile' === newMode) {
                            $tiles
                                .sort(function (a, b) {
                                    return sortHelper(a, b, 'sorting');
                                })
                                .appendTo($columns.first())
                        } else {
                            $tiles.each(function () {
                                var $tile = $(this),
                                    column = $tile.data('originalColumn') || 0;

                                $tile.appendTo($columns.eq(column));
                            });

                            $columns.each(function () {
                                var $column = $(this);

                                $column.find('.ce-tile')
                                    .sort(function (a, b) {
                                        return sortHelper(a, b, 'originalSorting');
                                    })
                                    .appendTo($column)
                            })
                        }

                        currentMode = newMode;
                    }
                }

                this._$window.on('resize', handler.bind(this));
                this._$document.on('ready', handler.bind(this));
            }.bind(this));

            return this;
        },

        _initClickToEnhance: function () {
            $('[data-widget="click-to-enhance"]').each(function () {
                const $container = $(this);
                const $toggle = $container.find('[data-action="toggle"]');
                const $content = $container.find('[data-target="content"]');

                $toggle.on('click', function () {
                    const isHidden = $content.attr('hidden');

                    if (isHidden) {
                        $content.attr('hidden', false).hide().slideDown(300);
                        $toggle.children().text($toggle.data('textHide'));
                    } else {
                        $content.slideUp(300, function () {
                            $content.attr('hidden', true);
                        });
                        $toggle.children().text($toggle.data('textShow'));
                    }

                    $toggle
                        .toggleClass('click-to-enhance__toggle--show', !isHidden)
                        .toggleClass('click-to-enhance__toggle--hide', isHidden)
                        .blur();
                });
            });

            return this;
        },

        _initShareLinks: function () {
            $('.share-icon').on('click', function () {
                var url = $(this).attr('href');

                if (!/^mailto:/.test(url)) {
                    window.open(url, 'social', 'width=1024,height=768');
                    event.preventDefault();
                }
            });

            return this;
        },

        _initForms: function () {
            var handler = function () {
                var $field = $(this);

                if('' !== $field.val()){
                    $field.addClass('filled');
                }else{
                    $field.removeClass('filled');
                }
            };

            $('.form__field-wrapper:not(.form__field-wrapper--radio)').find('input, textarea')
                .each(handler)
                .focusout(handler);

            $('.form__field-wrapper:not(.form__field-wrapper--radio)').find('select')
                .each(handler)
                .change(handler);

            return this;
        },

        _initIframes: function () {
            $('.ce-iframe > iframe').iFrameResize();

            return this;
        },

        _initAnimations: function () {
            var $body = $('body'),
                sr;

            if ($body.hasClass('no-animations')) {
                return;
            }

            sr = new ScrollReveal({
                viewFactor: 0,
                scale: 1,
                duration: 600,
                easing: 'ease-in-out'
            });

            if (sr.isSupported()) {
                sr.reveal('[data-animate="slide-in"]');
            } else {
                $body.addClass('no-animations');
            }

            return this;
        },

        _initShowElement: function () {
            $('body').on('click', '[data-action="show-element"]', function (event) {
                var $link = $(event.currentTarget);
                var $target = $($link.attr('href'));

                if ($target.length) {
                    event.preventDefault();

                    $link.slideUp(300, function () {
                        $target.slideDown(300, function () {
                            $link.remove();
                        });
                    });
                }
            });

            return this;
        },

        _initCookieConsent: function () {
            [...document.querySelectorAll('[href="#cookie-banner"]')].forEach((el) => {
                el.addEventListener('click', (event) => {
                    event.preventDefault();

                    klaro.show();
                }, false);
            });

            return this;
        },

        _getViewportWidth: function () {
            return Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
        },

        _smoothScroll: function (offset) {
            var dfd = $.Deferred();

            $('html, body').animate({
                scrollTop: offset
            }, 300, function () {
                dfd.resolveWith(this);
            }.bind(this));

            return dfd.promise();
        }
    };

    $(function () {
        window.trendmanagerSite = new Site();
    });

})(jQuery);